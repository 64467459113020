import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { AppCoverPageComponent } from './cover-page/app-cover-page.component';
import { AuthGuard } from './app-auth-guard';
import { AppMentionPageComponent } from './cover-page/app-mention-page.component';
import { CustomLoginComponent } from './custom-login/custom-login.component';
import { CustomRegisterComponent } from './custom-register/custom-register.component';
import { AppPolicyPageComponent } from './cover-page/app-policy-page.component';

var cors = require('cors');


const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'home',
    component: AppCoverPageComponent,
  },
  {
    path: 'home/mention',
    component: AppMentionPageComponent,
  },
  {
    path: 'home/policy',
    component: AppPolicyPageComponent,
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: CustomLoginComponent,
      },
      {
        path: 'login',
        component: CustomLoginComponent,
      },
      {
        path: 'register',
        component: CustomRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },

    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
