import { SrvRecord } from 'dns';
import { Time } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';

export interface Geometry {
    type: string;
    coordinates: number[];
}

export interface Properties {
    label: string;
    score: number;
    id: string;
    type: string;
    x: number;
    y: number;
    importance: number;
    name: string;
    postcode: string;
    citycode: string;
    city: string;
    context: string;
    distance: number;
}

export interface Feature {
    type: string;
    geometry: Geometry;
    properties: Properties;
}

export interface Filters {
    type: string;
}

export interface FeatureCollection {
    type: string;
    version: string;
    features: Feature[];
    attribution: string;
    licence: string;
    filters: Filters;
    limit: number;
}

export interface IAddress {
    country: string;
    city: string;
    numero: string;
    postalCode: string;
    street: string;
}

export interface IAddress {
    country: string;
    city: string;
    numero: string;
    postalCode: string;
    street: string;
}

export interface IHoraire {
    number: number;
    dur: number;
    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;
    isRecurrence: string;
    recurrence: IRecurrence;
    marker_appearance: any;
}

export interface IRecurrence {
    startDatePeriod: Date;
    endDatePeriod: Date;
    periodicity: IPeriodicity;
}

export interface IPeriodicity {
    type: string;
    object: any;
}
export interface IHebdomadaire extends IPeriodicity {
    number: number;
    lundi: boolean;
    mardi: boolean;
    mercredi: boolean;
    jeudi: boolean;
    vendredi: boolean;
    samedi: boolean;
    dimanche: boolean;
}
export interface IQuotidienne extends IPeriodicity {
    number: number;
}
export interface IMensuelle extends IPeriodicity {
    number_1: number;
    number_2: number;
    day: string;
    order: string;
}
export interface IAnnuelle extends IPeriodicity {
    number_1: number;
    number_2: number;
    day: string;
    order: string;
    month: string;
}

export interface IPeriode {
    number: number;
    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;
    cancel: boolean;
}

export interface IBloc {
    number: number;
    name: string;
    content: string;
}
export interface TokenMedia {
    stream_token: string    
}

export interface IMedia {
    id?: number;
    number: number;
    buffer: string | ArrayBuffer;
    file: File;
    duration: number;
    url: string | SafeUrl;
    type: string;
}

export interface ILienHof {
    number: number;
    id: number;
}

export interface TreeNode<T> {
    data: T;
    children?: TreeNode<T>[];
    expanded?: boolean;
}

export interface FSEntry {
    name?: string;
    type?: string;
    category?: string;
    city?: string;
    postCode?: string;
    address?: string;
    id: number;
    reccurence?: string;
    kind?: string;

}

export interface FSEntryPayment {
    invoiceDate: string;
    serviceDesc: string;
    amount: number;
}


export interface IFSEntryMessage {
    username?: string;
    city?: string;
    message?: string;
    date?: string;
    kind?: string;
    pj?: string;
    number?: number;
}

export interface IFSEntrySub {
    name: string
    email: string
    message: string
    city: string
    postcode: string
    phoneNumber: string
    date: string
}

export class FSEntrySub implements IFSEntrySub {
    constructor(name: string,
        email: string,
        message: string,
        city: string,
        postcode: string,
        phoneNumber: string,
        date: string) {
            this.name = name;
            this.email = email; 
            this.message = message;
            this.city = city;
            this.postcode = postcode;
            this.phoneNumber = phoneNumber;
            this.date = date;
        }
        name: string
        email: string
        message: string
        city: string
        postcode: string
        phoneNumber: string
        date: string
}

export class FSEntryMessage implements IFSEntryMessage {
    constructor(username: string,
        city: string,
        message: string,
        date: string,
        kind: string,
        pj: string,
        number: number) {

        this.username = username;

        this.city = city;
        this.message = message;
        this.date = date;
        this.kind = kind;
        this.pj = pj;
        this.number = number;
    }
    username?: string;
    city?: string;
    message?: string;
    date?: string;
    kind?: string;
    pj?: string;
    number?: number;
}

export interface ICategory {
    id: number;
    name: string;
}
export interface IEpic {
    id: number;
    name: string;
    weekly_number_souvenir: number;
    short_text: string;
    epic_type: string;
    epic_type_id: number;
    information_epic: InformationEpic;
    category: Category;
    address: Address;
    latitude: number;
    longitude: number;
    contact: Contact;
    description_bloc: DescriptionBloc[];
    description_summary: DescriptionSummary;
    accessibility: boolean;
    media_list: IMediaList[];
    media_information: MediaInformation;
    status?: any;
    epic_link_hall_of_fame?: any;
    services: Service[];
    tags: Tag[];
    schedule_data: ScheduleData[];
    inactive_period_data: InactivePeriodData[];
}


export interface InformationEpic {
    id: number;
    epic_creation: string;
    epic_update: string;
    perturbation: boolean;
}

export interface Address {
    id: number;
    number: string;
    street: string;
    latitude: number;
    longitude: number;
    zip_code: string;
    city: string;
    country: string;
}

export interface MarkerAppearance {
    id: number;
    weekly_number: number;
}

export interface Contact {
    id: number;
    mobile: string;
    email: string;
}

export interface DescriptionBloc {
    id: number;
    title: string;
    checked : boolean;
    content: string;
    order: number;
}

export interface DescriptionSummary {
    id: number;
    pop_up: string;
    bar_menu: string;
}

export interface IMediaList {
    id: number;
    url: string;
    stream: string;
    size: number;
    file_name: string;
    order: number;
    title: string;
    date: Date;
    type: string;
    stream_token: string;
    duration: number;
    delete: boolean;
}

export interface MediaInformation {
    id: number;
    total_picture: number;
    total_video: number;
    total_duration: number;
}

export interface Service {
    id: number;
    name: string;
}

export interface Tag {
    id: number;
    name: string;
}

export interface Start {
    id: number;
    date_time: string;
    timezone?: any;
}

export interface End {
    id: number;
    date_time: string;
    timezone?: any;
}

export interface AppointmentTime {
    id: number;
    start: string;
    end: string;
    startTime: string;
    endTime: string;
    duration: number;
}

export interface Frequency {
    type: string;
    id: number;
    annual_frequency?: number;
    annual_frequency_number?: string;
    annual_frequency_day?: string;
    annual_frequency_order?: string;
    annual_frequency_month?: string;
    daily_frequency_value?: string;
    daily_workday?: boolean;
    monthly_frequency_number?: string;
    monthly_frequency_order?: string;
    monthly_frequency_day?: string;
    monthly_frequency_day_of_week?: string;
    weekly_frequency_value?: string;
    weekly_days?: string[];
}

export interface RangeRecurrence {
    id: number;
    start_date: string;
    end_date: string;
}

export interface RecurrenceData {
    id: number;
    frequency: Frequency;
    range_recurrence: RangeRecurrence;
}

export interface ScheduleData {
    id: number;
    order: number;
    recurrence: string;
    appointment_time: AppointmentTime;
    marker_appearance: MarkerAppearance;    
    recurrence_data: RecurrenceData;
}

export interface InactivePeriodData {
    id: number;
    number: number;
    inactive_period_start: string;
    inactive_period_end: string;
}




export class Epic implements IEpic {
    dc_identifier?: any;
    external_source?: any
    occurrence_list?: any;
    epic_type_id: number;
    id: number;
    weekly_number_souvenir: number;
    name: string;
    short_text: string;
    epic_type: string;
    information_epic: InformationEpic;
    category: Category;
    address: Address;
    latitude: number;
    longitude: number;
    contact: Contact;
    description_bloc: DescriptionBloc[];
    description_summary: DescriptionSummary;
    accessibility: boolean;
    media_list: IMediaList[];
    media_information: MediaInformation;
    status?: any;
    epic_link_hall_of_fame?: any;
    services: Service[];
    tags: Tag[];
    schedule_data: ScheduleData[];
    inactive_period_data: InactivePeriodData[];
}


export interface IGetWrapper {
    res_code: number;
    app_code: string;
    app_msg: string;
    data: any;
}

export class GetWrapper implements IGetWrapper {
    res_code: number;
    app_code: string;
    app_msg: string;
    data: any;
}


export class Category implements ICategory {
    id: number;
    name: string;
    code: string;    
}

export interface IData {
    id: number;
    firstname: string;
    lastname: string;
    contact: IContact;
    payments?: any[];
}

export interface IContact {
    id: number;
    mobile: string;
    email: string;
    landline: string;
}

export interface IUser {
    id: number;
    username: string;
    roles: any[];
    city?: any;
    users: any[];
    data: IData;
}


export class User implements IUser {
    constructor(username: string, data: Data) {
        this.username = username;
        this.data = data;
    }
    id: number;
    username: string;
    roles: any[];
    users: any[];
    cities?: any[];
    inter_communes?: any[];
    data: IData;
}

export class Data implements IData {
    constructor(firstname: string, lastname: string, contact: IContact) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.contact = contact;
    }
    id: number;
    firstname: string;
    lastname: string;
    contact: IContact;
}

export class Contact implements IContact {
    constructor(mobile: string, email: string) {
        this.mobile = mobile;
        this.email = email;
    }
    id: number;
    mobile: string;
    email: string;
    landline: string;

}