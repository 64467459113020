import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { Epic, IMediaList, IMedia, GetWrapper, IUser, IFSEntrySub, ICategory, Category, TokenMedia, IEpic, IGetWrapper } from './event.model';
import { NbAuthJWTToken } from '@nebular/auth';
import { AuthGuard } from '../../app-auth-guard';
import { RepositionScrollStrategy } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';




@Injectable()
export class EpicService {
  epic: Epic;
  epics: Epic[] = []
  epicsRequest: Epic[] = []
  tokenMedia: String
  city: String
  referents: IUser[];
  referent: any;
  categories: Category[];
  user: IUser;
  cities: any[];
  users: any[];
  subs: any[];
  private root: string;
  private corsHeaders: HttpHeaders;
  account: any;
  constructor(private http: HttpClient, private authGuard: AuthGuard) {
    this.root = environment.root + "/v1";
    this.corsHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    /*
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    'Access-Control-Allow-Headers': "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
    'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    "Access-Control-Expose-Headers" : "Access-Control-*"
   */
  }


  clear() {
    this.epic = null;
    this.epics = [];
    this.tokenMedia = "";
    this.city = "";
    this.referents = null;
    this.referent = null;
    this.user = null;
    this.cities = [];
    this.users =[];
    this.subs =[];
    this.account = null;
  }

  postEpic(epic: Epic) {
    let promise = new Promise<void>((resolve, reject) => {

      console.log(epic);
      this.http
        .post<Epic>(this.root + "/users/epic", JSON.stringify(epic), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.epic = res;
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  postMultipleEpicRequest(epics: Epic[]) {
    let promise = new Promise<GetWrapper>((resolve, reject) => {
      this.http
        .post<GetWrapper>(this.root + "/users/epics/request", JSON.stringify(epics), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  deleteMultipleEpicRequest(ids: number[]) {
    let promise = new Promise<GetWrapper>((resolve, reject) => {
      this.http
        .post<GetWrapper>(this.root + "/request-adm/epics/delete", JSON.stringify(ids), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getKpi(choiceCities: any[]) {
    let promise = new Promise<any[]>((resolve, reject) => {
      let kpi : any[] = [];
      choiceCities.forEach(c => {
        kpi.push( {
          codeGeo : c.code_geo
        })
      })
      this.http
        .post<GetWrapper>(this.root + "/request-adm/epics/kpi", JSON.stringify(kpi), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            if(res.app_code == "OK") {
              resolve(res.data);
            } else {
              reject(res.app_msg);
            }
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  postMedia(epic: Epic) {
    let promise = new Promise<Array<IMediaList>>((resolve, reject) => {


      this.http
        .post<Array<IMediaList>>(this.root + "/users/epics/"+epic.id+"/media", JSON.stringify(epic.media_list), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getEpic(postCode : String, codeGeo : String) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/epics/query?postcode=" + postCode + "&intercommunal=false&codeGeo="+codeGeo;
      this.http
        .get<GetWrapper>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.epics = [];
            if(res.data.city_data != null) {
              res.data.city_data.forEach(element => {
                if(element.epic_data != null) {
                  element.epic_data.forEach((e: Epic) => this.epics.push(e));
                }
              });
            }        
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getEpicRequest(postCode : String, codeGeo : String) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/request-adm/epics/query?postcode=" + postCode + "&codeGeo="+codeGeo;
      this.http
        .get<GetWrapper>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.epicsRequest = [];
            if(res.data != null) {
              res.data.forEach(element => {
               this.epicsRequest.push(element);                
              });
            }        
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getEpicRequestMultiple(inseeList : String[]) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/request-adm/epics/query";
      this.http
        .post<GetWrapper>(apiURL,inseeList, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.epicsRequest = [];
            if(res.data != null) {
              res.data.forEach(element => {
               this.epicsRequest.push(element);                
              });
            }        
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }




  getAccount() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/account";
      this.http
        .get<IGetWrapper>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.account = res.data.user_data[0];
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTokenMedia(postCode : String, codeGeo : String) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = "";
      if(codeGeo) {
        apiURL = this.root + "/users/epics/tokenMedia/query?postcode=" + postCode + "&codeGeo=" + codeGeo;
      } else {
        apiURL = this.root + "/users/epics/tokenMedia/query?postcode=" + postCode;
      }
      this.http
        .get<IGetWrapper>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            if(res.app_code == "OK") {
              this.tokenMedia = res.data.token;
              resolve();
            } else {
              reject(res.app_msg);
            }
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getCategories() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/repositories/categories";
      this.http
        .get<Category[]>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.categories = res;
            resolve();
            console.log(res);
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getReferent() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/referent";
      this.http
        .get<GetWrapper>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            if(res.app_code == "OK") {
              this.referents = res.data;
              resolve(res.data);
            }  else {
              reject(res.app_msg);     
            }   
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  postReferent(user : IUser) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post<GetWrapper>(this.root + "/users/referent", JSON.stringify(user), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            if(res.app_code == "OK") {
              resolve(res.data);
            }  else {
              reject(res.app_msg);     
            }          
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  putReferent(user : IUser) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .put<GetWrapper>(this.root + "/users/referent", JSON.stringify(user), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            if(res.app_code == "OK") {
              resolve(res.data);
            }  else {
              reject(res.app_msg);     
            }   
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUser() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/";
      this.http
        .get<IUser>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.user = res;
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  public getPDF(): Observable<Blob> {
    //const options = { responseType: 'blob' }; there is no use of this
    let uri = this.root + "/users/file/mandat_sepa.pdf";
    // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
    return this.http.get(uri, { responseType: 'blob', headers: this.corsHeaders.append('Authorization', this.authGuard.token) });
  }

  public showPDF(): void {
    this.getPDF()
      .subscribe(x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "mandat_sepa.pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }

  updateUser() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/update";
      this.http
        .post<IUser>(apiURL, JSON.stringify(this.user), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  updateEpic(epic : Epic) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/epics/" + epic.id;
      this.http
        .put<GetWrapper>(apiURL, JSON.stringify(epic), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success 
            if(res.app_code == "OK") {
              this.epic = res.data;
              resolve(res.data);
            }  else {
              reject(res.app_msg);     
            }           
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  resetPassword(resetPassword: any) {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/users/reset-password";
      this.http
        .post<any>(apiURL, JSON.stringify(resetPassword), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            console.log(res);
            resolve();
          },
          msg => {
            // Error
            reject(msg);
            console.log(msg);
          }
        );
    });
    return promise;
  }


  getCities() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/adm/city";
      this.http
        .get<any[]>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.cities = res;
            console.log(res);
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUsers() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/adm/user";
      this.http
        .get<any[]>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.users = res;
            console.log(res);
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getSub() {
    let promise = new Promise<void>((resolve, reject) => {
      let apiURL = this.root + "/sub";
      this.http
        .get<any[]>(apiURL, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            this.subs = res;
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  postCity(city: any) {
    console.log(city);
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post<any>(this.root + "/adm/city", JSON.stringify(city), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  postUser(data: any) {
    let u = {
      username: data.username,
      roles: [{roleName: data.role}],
      city: {zip_code: data.zip_code}
    }
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post<any>(this.root + "/adm/user", JSON.stringify(u), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  postReferant(data: any) {
    let u = {
      username: data.username,
      roles: [{roleName: "REFERANT"}],
      city: {zip_code: data.zip_code}
    }
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post<any>(this.root + "/adm/user", JSON.stringify(u), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  putUser(data: any) {
    let u = {
      username: data.username,
      roles: [{roleName: data.role}],
      city: {zip_code: data.zip_code}
    }
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .put<any>(this.root + "/adm/user/" + data.id, JSON.stringify(u), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  putCity(city: any) {
    console.log(city);
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .put<any>(this.root + "/adm/city/" + city.id, JSON.stringify(city), { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  deleteCity(city: any) {
    console.log(city);
    console.log(this.root + "/adm/city/" + city.id);

    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .delete<any>(this.root + "/users/adm/city/" + city.id, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteEpic(epic: IEpic) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .delete<any>(this.root + "/users/epics/" + epic.id, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteEpicRequest(id: number) {
    const req = new HttpRequest('DELETE', this.root + "/request-adm/epics/" + id,{ headers: this.corsHeaders.append('Authorization', this.authGuard.token) });
    return this.http.request(req);  
  }

  postEpicRequest(epic: IEpic) {
    const req = new HttpRequest('POST', this.root + "/users/epic",JSON.stringify(epic),{ headers: this.corsHeaders.append('Authorization', this.authGuard.token) });
    return this.http.request(req);  
  }

  deleteEpicRequestPromise(id: number) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .delete<any>(this.root + "/request-adm/epics/" + id, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteUser(user: any) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .delete<any>(this.root + "/adm/user/" + user.id, { headers: this.corsHeaders.append('Authorization', this.authGuard.token) })
        .toPromise()
        .then(
          res => {
            // Success
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }



  postSub(sub: IFSEntrySub) {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post<IFSEntrySub>(this.root + "/request/sub", JSON.stringify(sub), { headers: this.corsHeaders})
        .toPromise()
        .then(
          res => {
            resolve();
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }



  
  convertVideo(media: IMedia): Observable<HttpEvent<Blob>> {
    return this.pushFileToStorage(media, this.root + "/video/file/convert");
  };

  pushFileToStorage(media: IMedia, singleUrl: string): Observable<HttpEvent<Blob>> {
    const formdata: FormData = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', this.authGuard.token);
    console.log(media);
    formdata.append('file', media.file);
    formdata.append('duration', media.duration ? media.duration.toString() : '5' );
    const req = new HttpRequest('POST', singleUrl, formdata, {
      headers: headers,
      reportProgress: true,
      responseType: 'blob'
    });
    return this.http.request(req);
  }

}