import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { RoleProvider } from './pages/role.service';

@Injectable()
export class AuthGuard implements CanActivate {
  user : any
  token : string
  nbAuthJWTToken : NbAuthJWTToken;
  constructor(private roleProvider: RoleProvider,private authService: NbAuthService, private router: Router) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.nbAuthJWTToken = token;
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
          this.token = token.getValue();
          console.log(token.getPayload());
        }

      });

  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['home']);
          } else if (route.data && route.data.role && this.nbAuthJWTToken) {  
            let role =  this.nbAuthJWTToken.isValid() ? this.nbAuthJWTToken.getPayload()['realm_access']['roles'] : 'guest';
            let activate = false;
            role.forEach(element => {
              if(element == route.data.role) {
                activate = true;
              }
            });
            if(!activate && route.data.role) this.router.navigate(['home']);
          }
        }),
      );
  }
}